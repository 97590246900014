import React from 'react'

import Header from '../Header'
import Footer from '../Footer'
import Nav from '../Nav'
import MobileNav from '../MobileNav'
import Sidebar from '../Sidebar'

import './styles.css'

const Layout = ({ children }) => {
  return (
    <>
      <div className="main-wrapper">
        <Header />
      </div>
      <Nav />
      <MobileNav />
      <div className="main-wrapper">
        <div className="main-page">
          {children}
          <Sidebar />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Layout
