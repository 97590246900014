import React from 'react'
import { Link } from 'gatsby'

import './styles.css'

const Breadcrumbs = ({ post, isCategory = false, isSearch = false }) => {
  if (isCategory) {
    return (
      <div className="breadcrumbs">
        <Link to="/pt">Home</Link>
        {'»'}
        <span>{post.name}</span>
      </div>
    )
  } else if (isSearch) {
    return (
      <div className="breadcrumbs">
        <Link to="/pt">Home</Link>
        {'»'}
        <span>{post}</span>
      </div>
    )
  }

  return (
    <div className="breadcrumbs">
      <Link to="/pt">Home</Link>
      {'»'}
      <Link to={`/${post.language.slug}/category/${post.category.slug}`}>
        {post.category.name}
      </Link>
      {'»'}
      <span>{post.name}</span>
    </div>
  )
}

export default Breadcrumbs
