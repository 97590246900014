import React from 'react'

const Loading = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        margin: 'auto',
        background: 'transparent',
        display: 'block',
        shapeRendering: 'auto',
      }}
      width="60px"
      height="60px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle cx="84" cy="50" r="10" fill="#921245">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="0.3731343283582089s"
          calcMode="spline"
          keyTimes="0;1"
          values="10;0"
          keySplines="0 0.5 0.5 1"
          begin="0s"
        ></animate>
        <animate
          attributeName="fill"
          repeatCount="indefinite"
          dur="1.4925373134328357s"
          calcMode="discrete"
          keyTimes="0;0.25;0.5;0.75;1"
          values="#921245;#e6a8c0;#ca7194;#d83576;#921245"
          begin="0s"
        ></animate>
      </circle>
      <circle cx="16" cy="50" r="10" fill="#921245">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1.4925373134328357s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="0s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="1.4925373134328357s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="0s"
        ></animate>
      </circle>
      <circle cx="50" cy="50" r="10" fill="#d83576">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1.4925373134328357s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.3731343283582089s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="1.4925373134328357s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.3731343283582089s"
        ></animate>
      </circle>
      <circle cx="84" cy="50" r="10" fill="#ca7194">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1.4925373134328357s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.7462686567164178s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="1.4925373134328357s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-0.7462686567164178s"
        ></animate>
      </circle>
      <circle cx="16" cy="50" r="10" fill="#e6a8c0">
        <animate
          attributeName="r"
          repeatCount="indefinite"
          dur="1.4925373134328357s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="0;0;10;10;10"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.1194029850746268s"
        ></animate>
        <animate
          attributeName="cx"
          repeatCount="indefinite"
          dur="1.4925373134328357s"
          calcMode="spline"
          keyTimes="0;0.25;0.5;0.75;1"
          values="16;16;16;50;84"
          keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
          begin="-1.1194029850746268s"
        ></animate>
      </circle>
    </svg>
  )
}

export default Loading
