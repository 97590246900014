import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/en/Layout'
import PostList from '../../components/PostList'
import Breadcrumbs from '../../components/Breadcrumbs'
import SEO from '../../components/SEO'

const Tag = ({ data }) => {
  const tag = data.tagsEn
  const posts = data.allPostsEn.edges

  return (
    <Layout>
      <SEO
        title={tag.name}
        post={false}
        canonical={`${process.env.GATSBY_HOST_URL}/en/tags/${tag.slug}`}
      />

      <div className="category">
        <Breadcrumbs post={tag} isCategory={true} />

        <h1>{tag.name}</h1>

        <PostList posts={posts} />
      </div>
    </Layout>
  )
}

export default Tag

export const query = graphql`
  query($id: String!, $slug: String!) {
    tagsEn(id: { eq: $id }) {
      name
      slug
    }

    allPostsEn(filter: { tags: { elemMatch: { slug: { eq: $slug } } } }) {
      edges {
        node {
          id
          name
          slug
          date(formatString: "DD/MM/YYYY", locale: "en-US")
          imageUrl
          language {
            slug
          }
          meta {
            content
          }
        }
      }
    }
  }
`
