import React, { useState } from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'

import './styles.css'

const Nav = () => {
  const [langToggle, setLangToggle] = useState(false)

  const handleToggleLang = () => {
    setLangToggle(!langToggle)
  }

  return (
    <div className="navigation">
      <div className="main-wrapper nav-wrapper">
        <nav>
          <ul>
            <li>
              <Link to="/en" activeClassName="active">
                Home
              </Link>
            </li>
            <li>
              <Link to="/en/tags" activeClassName="active">
                Tags
              </Link>
            </li>
            <li>
              <Link to="/en/about-us" activeClassName="active">
                About Us
              </Link>
            </li>
            <li>
              <Link to="/en/contact" activeClassName="active">
                Contact
              </Link>
            </li>
          </ul>
        </nav>

        <button className="lang-selection-btn" onClick={handleToggleLang}>
          <FontAwesomeIcon icon={faGlobe} />
        </button>

        {langToggle && (
          <div className="lang-selection">
            <ul>
              <li>
                <Link activeClassName="active" to="/pt" partiallyActive={true}>
                  Português
                </Link>
              </li>
              <li>
                <Link activeClassName="active" to="/en" partiallyActive={true}>
                  English
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default Nav
