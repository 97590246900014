import React from 'react'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'

import bannerPatreon from '../../../images/banner-en.png'

import './styles.css'

const Sidebar = () => {
  const data = useStaticQuery(graphql`
    query allMonthPopularEn {
      allMonthPopularEn {
        edges {
          node {
            posts {
              id
              name
              slug
            }
          }
        }
      }
    }
  `)

  const posts = data.allMonthPopularEn?.edges[0]?.node?.posts

  return (
    <aside className="sidebar">
      <div className="apoiase">
        <a
          href="https://www.patreon.com/hislamic"
          target="_blank"
          rel="noreferrer"
        >
          <img src={bannerPatreon} alt="Support us!" />
        </a>
      </div>

      <div className="most-read">
        <div className="most-read-header">
          <h3>Most popular</h3>
          <hr />
        </div>

        <div className="most-read-list">
          <ul>
            {posts.map((post) => (
              <li key={post.id}>
                <Link to={`/en/${post.slug}`}>{post.name}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </aside>
  )
}

export default Sidebar
