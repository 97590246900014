import React, { useState } from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faTimes,
  faRss,
  faMoneyBill,
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebook,
  faInstagram,
  faSpotify,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons'

const MobileNav = () => {
  const [toggle, setToggle] = useState(false)

  return (
    <div className="mobile-nav">
      <div className="mobile-nav-container">
        <button onClick={() => setToggle(!toggle)}>
          {toggle ? (
            <FontAwesomeIcon icon={faTimes} />
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </button>
      </div>

      <div
        className="mobile-nav-menu"
        style={{ display: toggle ? 'flex' : 'none' }}
      >
        <ul>
          <li>
            <Link to="/en" activeClassName="active">
              Home
            </Link>
          </li>
          <li>
            <Link to="/en/tags" activeClassName="active">
              Tags
            </Link>
          </li>
          <li>
            <Link to="/en/about-us" activeClassName="active">
              About Us
            </Link>
          </li>
          <li>
            <Link to="/en/contact" activeClassName="active">
              Contact
            </Link>
          </li>
        </ul>

        <div className="social-icons">
          <a
            href="https://historiaislamica.com.br/en/feed/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faRss} />
          </a>
          <a
            href="https://www.facebook.com/goislamichistory/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </div>
      </div>
    </div>
  )
}

export default MobileNav
