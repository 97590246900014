import React from 'react'
import { Link } from 'gatsby'

import MadeWithIcon from '../../../images/icons/MadeWith'

import './styles.css'

const Footer = () => {
  return (
    <footer>
      <div className="left-bar">
        <p>
          © 2020 Islamic History | Made with love for{' '}
          <MadeWithIcon width={27} /> by Taha Brasil
        </p>
      </div>

      <div className="right-bar">
        <nav>
          <ul>
            <li>
              <Link to="/en">Home</Link>
            </li>
            <li>
              <Link to="/en/tags">Tags</Link>
            </li>
            <li>
              <Link to="/en/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/en/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/en/contact">Contact</Link>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  )
}

export default Footer
