import React, { useRef, useState, useLayoutEffect } from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import sanitizeHtml from 'sanitize-html'

import LoadingIcon from '../../images/icons/Loading'

import './styles.css'

const PostList = ({ posts }) => {
  const infiniteScrollEl = useRef(null)

  const [infiniteLoading, setInfiniteLoading] = useState(false)
  const [infiniteLastItem, setInfiniteLastItem] = useState(false)

  const [currentPaginationValue, setCurrentPaginationValue] = useState(12)
  const [localPosts, setLocalPosts] = useState(posts.slice(0, 12))

  const handleLoadItems = async () => {
    if (posts.length === localPosts.length) {
      setInfiniteLoading(false)
      return
    }

    setInfiniteLoading(true)

    setCurrentPaginationValue(currentPaginationValue + 12)
    setLocalPosts(posts.slice(0, currentPaginationValue))

    setTimeout(() => {
      setInfiniteLoading(false)
    }, 500)
  }

  useLayoutEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      const ratio = entries[0].intersectionRatio

      if (ratio && ratio > 0 && !infiniteLoading && !infiniteLastItem) {
        handleLoadItems()
      }
    })

    if (infiniteScrollEl.current) {
      intersectionObserver.observe(infiniteScrollEl.current)
    }

    return () => intersectionObserver.disconnect()
  })

  const getExcerpt = (content) => {
    if (!content) return

    const excerpt = sanitizeHtml(content.slice(0, 153), {
      allowedTags: ['a', 'b', 'i'],
      allowedAttributes: {
        a: ['href'],
      },
    })

    return `${excerpt}...`
  }

  return (
    <div className="post-list">
      {localPosts.map((post) => (
        <article key={post.node.id}>
          <Link to={`/${post.node.language.slug}/${post.node.slug}`}>
            <div
              className="post-image"
              style={{
                background: `url(${post.node.imageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            ></div>
          </Link>
          <div className="post-metadata">
            <small>
              <FontAwesomeIcon icon={faClock} />
              {post.node.date}
            </small>
            <Link to={`/${post.node.language.slug}/${post.node.slug}`}>
              <h3>{post.node.name}</h3>
            </Link>
            <p
              dangerouslySetInnerHTML={{
                __html: getExcerpt(
                  post.excerpt ? post.excerpt : post.node.meta.content
                ),
              }}
            ></p>
            <Link
              to={`/${post.node.language.slug}/${post.node.slug}`}
              className="read-more-btn"
            >
              {post.node.language.slug === 'pt' ? `Leia mais »` : `Read more »`}
            </Link>
          </div>
        </article>
      ))}

      <div className="infinite-loading-el" ref={infiniteScrollEl}>
        {infiniteLoading && (
          <div className="loading-spinner">
            <LoadingIcon />
          </div>
        )}
        {infiniteLastItem && <hr />}
      </div>
    </div>
  )
}

export default PostList
